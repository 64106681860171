import { AjaxError } from 'rxjs/ajax';
import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';

import { TransactionsResponse } from '../model/transactions';
import { SearchQueryValue } from '../model/filter';

export enum TRANSACTIONS_P2P_TYPES {
  TRANSACTIONS_P2P_INIT = '[TRANSACTIONS P2P] INIT',
  TRANSACTIONS_P2P_REFRESH = '[TRANSACTIONS P2P] REFRESH',
  TRANSACTIONS_P2P_SEARCH_QUERY = '[TRANSACTIONS P2P] SEARCH QUERY',
  TRANSACTIONS_SEARCH_REQUEST = '[TRANSACTIONS] SEARCH REQUEST',
  TRANSACTIONS_LOAD_MORE_REQUEST = '[TRANSACTIONS] LOAD MORE REQUEST',
  TRANSACTIONS_SEARCH_RESPONSE = '[TRANSACTIONS] SEARCH RESPONSE',
  TRANSACTIONS_SEARCH_ERROR = '[TRANSACTIONS] SEARCH ERROR',
  TRANSACTIONS_UPDATE_ALL_REGION_BANK_TOGGLE_STATE = '[TRANSACTIONS] ALL REGION TOGGLE',
}

export const Actions = {
  init: () => {
    return createAction(TRANSACTIONS_P2P_TYPES.TRANSACTIONS_P2P_INIT);
  },
  searchQueryUpdate: (searchQuery: SearchQueryValue) => {
    return createAction(TRANSACTIONS_P2P_TYPES.TRANSACTIONS_P2P_SEARCH_QUERY, { searchQuery });
  },
  searchRequest: (searchQuery: SearchQueryValue) => {
    return createAction(TRANSACTIONS_P2P_TYPES.TRANSACTIONS_SEARCH_REQUEST, { searchQuery });
  },
  refreshRequest: () => {
    return createAction(TRANSACTIONS_P2P_TYPES.TRANSACTIONS_P2P_REFRESH);
  },
  loadMoreRequest: () => {
    return createAction(TRANSACTIONS_P2P_TYPES.TRANSACTIONS_LOAD_MORE_REQUEST);
  },
  searchResponse: (transactionsResponse: TransactionsResponse) => {
    return createAction(TRANSACTIONS_P2P_TYPES.TRANSACTIONS_SEARCH_RESPONSE, { transactionsResponse });
  },
  searchError: (error: AjaxError) => {
    return createAction(TRANSACTIONS_P2P_TYPES.TRANSACTIONS_SEARCH_ERROR, error);
  },
  updateAllRegionalBankToggleState: (allRegionBankToggleState: boolean) => {
    return createAction(TRANSACTIONS_P2P_TYPES.TRANSACTIONS_UPDATE_ALL_REGION_BANK_TOGGLE_STATE, allRegionBankToggleState);
  },
};

export type ActionsType = ActionsUnion<typeof Actions>;
