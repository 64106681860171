import { ActionsObservable, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { filter, flatMap, map, switchMap } from 'rxjs/operators';
import { ofType } from '@martin_hotell/rex-tils';
import { push } from 'connected-react-router';

import { AppState } from 'store/rootState';
import { PAYLIB_REALM } from 'config';
import { ErrorType } from 'features/shared/Error/ErrorPage';
import * as fromActions from './actions';
import { UserProfile } from '../model';
import { realmSelector, rolesSelector, userProfileSelector } from './selectors';
import { keycloak } from '../keycloak';
import { KeycloakError, KeycloakProfile } from 'keycloak-js';

export const keycloakInitRequest = (action$: ActionsObservable<fromActions.ActionsType>) =>
  action$.pipe(
    ofType(fromActions.AUTHENTICATION_TYPES.KEYCLOAK_INIT_REQUEST),
    switchMap(() => {
      return new Observable<fromActions.ActionsType>((subscriber) => {
        keycloak
          .init({ onLoad: 'login-required' })
          .success((isAuthenticated: boolean) => {
            subscriber.next(fromActions.Actions.keycloakInitSuccess(keycloak.realm!, isAuthenticated, keycloak.token));
            subscriber.complete();
          })
          .error((error: KeycloakError) => {
            subscriber.next(fromActions.Actions.keycloakInitError(error));
            subscriber.complete();
          });
      });
    }),
  );

export const keycloakInitSuccess = (action$: ActionsObservable<fromActions.ActionsType>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(fromActions.AUTHENTICATION_TYPES.KEYCLOAK_INIT_SUCCESS),
    filter((action) => action.payload.isAuthenticated),
    map(() => {
      const roles = rolesSelector(state$.value);
      if (roles.length === 0) {
        return fromActions.Actions.userInsufficientPrivileges();
      } else {
        return fromActions.Actions.requestUserProfile();
      }
    }),
  );

export const keycloakInitError = (action$: ActionsObservable<fromActions.ActionsType>) =>
  action$.pipe(
    ofType(fromActions.AUTHENTICATION_TYPES.KEYCLOAK_INIT_ERROR),
    map(() => push('/error')),
  );

export const requestUserProfile = (actions$: ActionsObservable<fromActions.ActionsType>) =>
  actions$.pipe(
    ofType(fromActions.AUTHENTICATION_TYPES.USER_PROFILE_REQUEST),
    switchMap(() => {
      return new Observable<fromActions.ActionsType>((subscriber) => {
        keycloak.loadUserProfile().success((userProfile: KeycloakProfile) => {
          subscriber.next(fromActions.Actions.userProfileResponse(userProfile as UserProfile));
          subscriber.complete();
        });
      });
    }),
  );

const userNotContainAllowBankData = (userProfile: UserProfile): boolean => {
  return (
    (!userProfile.allowedBankBics || userProfile.allowedBankBics.length === 0) &&
    (!userProfile.allowedGroupBankBics || userProfile.allowedGroupBankBics.length === 0)
  );
};

export const checkUserPrivileges = (actions$: ActionsObservable<fromActions.ActionsType>, state$: StateObservable<AppState>) =>
  actions$.pipe(
    ofType(fromActions.AUTHENTICATION_TYPES.USER_PROFILE_RESPONSE),
    map(({ payload }) => {
      const realm = realmSelector(state$.value);
      const userProfile = userProfileSelector(state$.value)!;

      if (realm !== PAYLIB_REALM && userNotContainAllowBankData(userProfile)) {
        return fromActions.Actions.userInsufficientPrivileges();
      } else {
        return fromActions.Actions.userProfileReady(payload);
      }
    }),
  );

export const insufficientPrivileges = (actions$: ActionsObservable<fromActions.ActionsType>) =>
  actions$.pipe(
    ofType(fromActions.AUTHENTICATION_TYPES.USER_INSUFFICIENT_PRIVILEGES),
    flatMap(() => {
      return [fromActions.Actions.reset(), push(`/error?reason=${ErrorType.INSUFFICIENT_PRIVILEGES}`), fromActions.Actions.logoutRequest()];
    }),
  );

export const logout = (actions$: ActionsObservable<fromActions.ActionsType>) =>
  actions$.pipe(
    ofType(fromActions.AUTHENTICATION_TYPES.LOGOUT_REQUEST),
    switchMap(() => {
      return new Observable<fromActions.ActionsType>((subscriber) => {
        keycloak.logout().success(() => {
          subscriber.next(fromActions.Actions.logoutSuccess());
        });
      });
    }),
  );

export default [keycloakInitRequest, keycloakInitSuccess, keycloakInitError, requestUserProfile, checkUserPrivileges, insufficientPrivileges, logout];
