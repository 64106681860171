import * as fromActions from './actions';
import { Announcement } from '../model';

export interface AnnouncementState {
  displayAnnouncement: boolean;
  announcements: Announcement[] | undefined;
}

export const INITIAL_ANNOUNCEMENT_STATE: AnnouncementState = {
  displayAnnouncement: false,
  announcements: undefined,
};

export const reducer = (state = INITIAL_ANNOUNCEMENT_STATE, action: fromActions.ActionsType): AnnouncementState => {
  switch (action.type) {
    case fromActions.ANNOUNCEMENT_TYPES.ANNOUNCEMENTS_LIST_RESPONSE:
      return {
        ...state,
        announcements: action.payload.announcements,
      };
    case fromActions.ANNOUNCEMENT_TYPES.DISPLAY_ANNOUNCEMENT:
      return {
        ...state,
        displayAnnouncement: true,
      };
    case fromActions.ANNOUNCEMENT_TYPES.ANNOUNCEMENT_DISPLAYED:
      return {
        ...state,
        displayAnnouncement: false,
      };
    default:
      return state;
  }
};
