import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import { hashCode } from '../shared/Utils/Strings';
import { UserProfile } from '../auth/model';
import { Announcement, UserLastConnection } from './model';

export const BASE_URL = '/common-static';

export const announcementsService = {
  getAnnouncements: (): Observable<Announcement[]> => {
    const url = `${BASE_URL}/announcements/weekly-gifs.json`;
    return ajax.getJSON<Announcement[]>(url);
  },
};

export const userLastConnectionService = {
  getHistory() {
    return (JSON.parse(localStorage.getItem('history') as string) as UserLastConnection[]) || [];
  },
  getUserLastConnectionDate(user: UserProfile): Date | null {
    const userHistory = this.getHistory().find(elem => elem.id === hashCode(user.username));
    return userHistory ? new Date(userHistory.lastConnection) : null;
  },
  saveUserLastConnection(user: UserProfile) {
    const history = this.getHistory();
    const userId = hashCode(user.username);
    const userHistory = history.find(elem => elem.id === userId);
    const newtHistory: UserLastConnection[] = userHistory
      ? history.map(elem => {
          // update
          if (elem.id !== userId) {
            return elem;
          }
          return {
            ...elem,
            lastConnection: new Date().toISOString(),
          };
        })
      : [...history, { id: userId, lastConnection: new Date().toISOString() }]; // insert

    localStorage.setItem('history', JSON.stringify(newtHistory));
  },
};
