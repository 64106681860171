import { AjaxError } from 'rxjs/ajax';

import * as fromActions from './actions';
import { Bank } from '../model/banks';

export interface ParamsState {
  loading: boolean;
  params: {
    banks: Bank[] | undefined;
  };
  error: AjaxError | null;
}

export const INITIAL_PARAMS_STATE: ParamsState = {
  loading: false,
  params: {
    banks: undefined,
  },
  error: null,
};

export const reducer = (state = INITIAL_PARAMS_STATE, action: fromActions.ActionsType): ParamsState => {
  switch (action.type) {
    case fromActions.PARAMS_TYPES.PARAMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case fromActions.PARAMS_TYPES.PARAMS_RESPONSE:
      return {
        loading: false,
        params: action.payload,
        error: null,
      };
    case fromActions.PARAMS_TYPES.PARAMS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
