import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';
import { KeycloakError } from 'keycloak-js';

import { UserProfile } from '../model';

export enum AUTHENTICATION_TYPES {
  AUTH_RESET = '[Authentication] RESET',
  KEYCLOAK_INIT_REQUEST = '[Authentication] KEYCLOAK REQUEST',
  KEYCLOAK_INIT_SUCCESS = '[Authentication] KEYCLOAK SUCCESS',
  KEYCLOAK_INIT_ERROR = '[Authentication] KEYCLOAK ERROR',
  USER_PROFILE_REQUEST = '[Authentication] USER PROFILE REQUEST',
  USER_PROFILE_RESPONSE = '[Authentication] GET USER RESPONSE',
  USER_PROFILE_READY = '[Authentication] USER PROFILE READY',
  USER_INSUFFICIENT_PRIVILEGES = '[Authentication] USER INSUFFICIENT PRIVILEGES',
  LOGOUT_REQUEST = '[Authentication] LOGOUT REQUEST',
  LOGOUT_SUCCESS = '[Authentication] LOGOUT SUCCESS',
  SET_REALM = '[Authentication] SET REALM',
}

export const Actions = {
  reset: () => createAction(AUTHENTICATION_TYPES.AUTH_RESET),
  keycloakInitRequest: () => createAction(AUTHENTICATION_TYPES.KEYCLOAK_INIT_REQUEST),
  keycloakInitSuccess: (realm: string, isAuthenticated: boolean, bearerToken: string | undefined) => {
    return createAction(AUTHENTICATION_TYPES.KEYCLOAK_INIT_SUCCESS, { realm, isAuthenticated, bearerToken });
  },
  keycloakInitError: (error: KeycloakError) => createAction(AUTHENTICATION_TYPES.KEYCLOAK_INIT_ERROR, error),
  requestUserProfile: () => createAction(AUTHENTICATION_TYPES.USER_PROFILE_REQUEST),
  userProfileResponse: (user: UserProfile) => createAction(AUTHENTICATION_TYPES.USER_PROFILE_RESPONSE, user),
  userInsufficientPrivileges: () => createAction(AUTHENTICATION_TYPES.USER_INSUFFICIENT_PRIVILEGES),
  userProfileReady: (user: UserProfile) => createAction(AUTHENTICATION_TYPES.USER_PROFILE_READY, user),
  logoutRequest: () => createAction(AUTHENTICATION_TYPES.LOGOUT_REQUEST),
  logoutSuccess: () => createAction(AUTHENTICATION_TYPES.LOGOUT_SUCCESS),
  setRealm: (realm: string) => createAction(AUTHENTICATION_TYPES.SET_REALM, realm),
};

export type ActionsType = ActionsUnion<typeof Actions>;
