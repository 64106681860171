import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';

export enum SHELL_TYPES {
  CHECK_APP_READY = '[SHELL] APP READY REQUEST',
  APP_READY = '[SHELL] APP READY RESPONSE',
}

export const Actions = {
  checkAppReady: () => createAction(SHELL_TYPES.CHECK_APP_READY),
  appReady: () => createAction(SHELL_TYPES.APP_READY),
};

export type ActionsType = ActionsUnion<typeof Actions>;
