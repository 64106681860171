import { AjaxError } from 'rxjs/ajax';
import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';

import { Announcement } from '../model';

export enum ANNOUNCEMENT_TYPES {
  USER_CONNECTED = '[Announcement] User Connected',
  DISPLAY_ANNOUNCEMENT = '[Announcement] Display Announcement',
  ANNOUNCEMENT_DISPLAYED = '[Announcement] Announcement Displayed',
  ANNOUNCEMENTS_LIST_REQUEST = '[Announcement] Request Announcements List',
  ANNOUNCEMENTS_LIST_RESPONSE = '[Announcement] Announcements List Response',
  ANNOUNCEMENTS_LIST_ERROR = '[Announcement] Announcements List Error',
}

export const Actions = {
  userConnected: () => createAction(ANNOUNCEMENT_TYPES.USER_CONNECTED),
  displayAnnouncement: () => createAction(ANNOUNCEMENT_TYPES.DISPLAY_ANNOUNCEMENT),
  announcementDisplayed: () => createAction(ANNOUNCEMENT_TYPES.ANNOUNCEMENT_DISPLAYED),
  requestAnnouncementsList: () => createAction(ANNOUNCEMENT_TYPES.ANNOUNCEMENTS_LIST_REQUEST),
  announcementsListResponse: (announcements: Announcement[]) =>
    createAction(ANNOUNCEMENT_TYPES.ANNOUNCEMENTS_LIST_RESPONSE, { announcements }),
  announcementsListError: (error: AjaxError) => createAction(ANNOUNCEMENT_TYPES.ANNOUNCEMENTS_LIST_ERROR, error),
};

export type ActionsType = ActionsUnion<typeof Actions>;
