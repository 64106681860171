import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';

import './error.scss';

export enum ErrorType {
  GENERIC = 'GENERIC',
  INSUFFICIENT_PRIVILEGES = 'INSUFFICIENT_PRIVILEGES',
}

const errorTypes = [ErrorType.GENERIC, ErrorType.INSUFFICIENT_PRIVILEGES];

export function ErrorPage({ location, history }: RouteComponentProps) {
  const { t } = useTranslation(['commons']);

  const queryParams = new URLSearchParams(location.search);
  const reason = errorTypes.find(type => type === queryParams.get('reason')) || ErrorType.GENERIC;

  return (
    <div className="error-container">
      <div className="error-box">
        <div className="error-message">{t(`commons:error.init.${reason}`)}</div>
      </div>
      <button className="ui inverted button" onClick={() => history.push('/')}>
        {t('commons:button.backHome')}
      </button>
    </div>
  );
}

export default withRouter(ErrorPage);
