import { of } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, ignoreElements, map, switchMap, tap } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { isSameWeek } from 'date-fns';
import { ofType } from '@martin_hotell/rex-tils';

import { AppState } from 'store/rootState';
import * as fromAppReadyActions from 'features/shell/store/actions';
import * as fromActions from './actions';
import { announcementsService, userLastConnectionService } from '../service';

export const announcementsRequestTrigger = (
  action$: ActionsObservable<fromAppReadyActions.ActionsType>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(fromAppReadyActions.SHELL_TYPES.APP_READY),
    map(() => state$.value.authentication.userProfile),
    filter(user => user !== undefined),
    switchMap(user => {
      const userLastConnection = userLastConnectionService.getUserLastConnectionDate(user!);
      if (!userLastConnection || !isSameWeek(new Date(), userLastConnection, { weekStartsOn: 1 })) {
        return of(fromActions.Actions.requestAnnouncementsList());
      }
      return of(fromActions.Actions.userConnected());
    })
  );

export const announcementsRequest = (action$: ActionsObservable<fromActions.ActionsType>) =>
  action$.pipe(
    ofType(fromActions.ANNOUNCEMENT_TYPES.ANNOUNCEMENTS_LIST_REQUEST),
    switchMap(() => {
      return announcementsService.getAnnouncements().pipe(
        map(announcements => fromActions.Actions.announcementsListResponse(announcements)),
        catchError((error: AjaxError) => of(fromActions.Actions.announcementsListError(error)))
      );
    })
  );

export const announcementsResponse = (action$: ActionsObservable<fromActions.ActionsType>) =>
  action$.pipe(
    ofType(fromActions.ANNOUNCEMENT_TYPES.ANNOUNCEMENTS_LIST_RESPONSE),
    map(() => fromActions.Actions.displayAnnouncement())
  );

export const displayAnnouncement = (action$: ActionsObservable<fromActions.ActionsType>) =>
  action$.pipe(
    ofType(fromActions.ANNOUNCEMENT_TYPES.DISPLAY_ANNOUNCEMENT),
    map(() => fromActions.Actions.userConnected())
  );

export const userConnected = (action$: ActionsObservable<fromActions.ActionsType>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(fromActions.ANNOUNCEMENT_TYPES.USER_CONNECTED),
    tap(() => {
      userLastConnectionService.saveUserLastConnection(state$.value.authentication.userProfile!);
    }),
    ignoreElements()
  );

export default [announcementsRequestTrigger, displayAnnouncement, userConnected, announcementsRequest, announcementsResponse];
