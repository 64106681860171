import { AjaxError } from 'rxjs/ajax';

import { ActionsUnion, createAction } from '@martin_hotell/rex-tils';
import { Params } from '../model/params';

export enum PARAMS_TYPES {
  PARAMS_REQUEST = '[PARAMS] PARAMS REQUEST',
  PARAMS_RESPONSE = '[PARAMS] PARAMS RESPONSE',
  PARAMS_ERROR = '[PARAMS] PARAMS ERROR',
  PARAMS_READY = '[PARAMS] PARAMS READY',
}

export const Actions = {
  paramsRequest: () => createAction(PARAMS_TYPES.PARAMS_REQUEST),
  paramsResponse: (params: Params) => createAction(PARAMS_TYPES.PARAMS_RESPONSE, params),
  paramsReady: () => createAction(PARAMS_TYPES.PARAMS_READY),
  paramsError: (error: AjaxError) => createAction(PARAMS_TYPES.PARAMS_ERROR, error),
};

export type ActionsType = ActionsUnion<typeof Actions>;
