import { Action, applyMiddleware, compose, createStore, Reducer } from 'redux';
import { ActionsObservable, createEpicMiddleware, StateObservable } from 'redux-observable';
import { History } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from 'store/HttpClient';

import { epic$ } from './rootEpic';
import { keycloak } from 'features/auth/keycloak';

export function configureStore(baseReducer: Reducer, history: History) {
  const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

  const httpClient = new HttpClient();

  const epicMiddleware = createEpicMiddleware({
    dependencies: { httpClient },
  });

  const store = createStore(baseReducer, composeEnhancers(applyMiddleware(routerMiddleware(history), epicMiddleware)));

  const rootEpic = (action$: ActionsObservable<Action>, state$: StateObservable<any>, deps: any): Observable<Action> =>
    epic$.pipe(mergeMap(epic => epic(action$, state$, deps)));

  epicMiddleware.run(rootEpic);

  httpClient.init(keycloak, store.dispatch);

  return { store, history, epicMiddleware };
}
