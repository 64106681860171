import { Observable, throwError } from 'rxjs';
import { Dispatch } from 'redux';
import { ajax, AjaxError } from 'rxjs/ajax';
import { catchError, flatMap, ignoreElements, map } from 'rxjs/operators';
import { KeycloakInstance } from 'keycloak-js';

import * as fromAuthActions from 'features/auth/store/actions';

export class HttpClient {
  private headers = {};
  private dispatch: Dispatch | undefined;
  private keycloak: KeycloakInstance | undefined;

  public init(keycloak: KeycloakInstance, dispatch: Dispatch) {
    this.keycloak = keycloak;
    this.dispatch = dispatch;
  }

  public get<T>(
    url: string,
    headers: Object = {},
    params: { [key: string]: string } = {},
  ) {
    let encodedParams: string[] = [];
    for (const key in params) {
      encodedParams.push(key + '=' + encodeURIComponent(params[key]));
    }
    let encodedUrl =
      encodeURI(url) +
      (encodedParams.length > 0 ? '?' + encodedParams.join('&') : '');
    return this.getDefaultHeaders().pipe(
      flatMap((defaultHeaders) => {
        return ajax
          .getJSON<T>(encodedUrl, { ...defaultHeaders, ...headers })
          .pipe(
            catchError((error: AjaxError) => {
              if ([302, 401].includes(error.status)) {
                this.dispatch &&
                  this.dispatch(fromAuthActions.Actions.logoutRequest());
                return ignoreElements();
              }
              return throwError(error);
            }),
          );
      }),
    );
  }

  public put<T>(url: string, body: Object, headers: Object = {}) {
    return this.getDefaultHeaders().pipe(
      flatMap((defaultHeaders) => {
        return ajax
          .put(encodeURI(url), body, { ...defaultHeaders, ...headers })
          .pipe(
            catchError((error: AjaxError) => {
              if ([302, 401].includes(error.status)) {
                this.dispatch &&
                  this.dispatch(fromAuthActions.Actions.logoutRequest());
                return ignoreElements();
              }
              return throwError(error);
            }),
          );
      }),
    );
  }

  public delete(url: string, headers: Object = {}) {
    return this.getDefaultHeaders().pipe(
      flatMap((defaultHeaders) => {
        return ajax
          .delete(encodeURI(url), { ...defaultHeaders, ...headers })
          .pipe(
            catchError((error: AjaxError) => {
              if ([302, 401].includes(error.status)) {
                this.dispatch &&
                  this.dispatch(fromAuthActions.Actions.logoutRequest());
                return ignoreElements();
              }
              return throwError(error);
            }),
          );
      }),
    );
  }

  private getDefaultHeaders() {
    return this.updateToken().pipe(
      map(() => {
        return {
          ...this.headers,
          Authorization:
            this.keycloak && this.keycloak.token
              ? `Bearer ${this.keycloak.token}`
              : undefined, // TODO
        };
      }),
    );
  }

  private updateToken() {
    return new Observable((subscriber) => {
      if (!this.keycloak) {
        return subscriber.next();
      }
      this.keycloak.updateToken(5).success(() => {
        subscriber.next();
      });
    });
  }
}
