import * as fromActions from './actions';
import { UserProfile } from '../model';
import { KeycloakError } from 'keycloak-js';

export interface AuthenticationState {
  realm: string | undefined;
  isAuthenticated: boolean;
  bearerToken: string | undefined;
  userProfile: UserProfile | undefined;
  error: KeycloakError | null;
}

export const INITIAL_AUTHENTICATION_STATE: AuthenticationState = {
  realm: undefined,
  isAuthenticated: false,
  bearerToken: undefined,
  userProfile: undefined,
  error: null,
};

export const reducer = (state = INITIAL_AUTHENTICATION_STATE, action: fromActions.ActionsType): AuthenticationState => {
  switch (action.type) {
    case fromActions.AUTHENTICATION_TYPES.SET_REALM:
      return {
        ...state,
        realm: action.payload,
      };
    case fromActions.AUTHENTICATION_TYPES.KEYCLOAK_INIT_SUCCESS:
      return {
        ...state,
        realm: action.payload.realm,
        isAuthenticated: action.payload.isAuthenticated,
        bearerToken: action.payload.bearerToken,
      };
    case fromActions.AUTHENTICATION_TYPES.USER_PROFILE_RESPONSE:
      return {
        ...state,
        userProfile: action.payload,
      };
    case fromActions.AUTHENTICATION_TYPES.KEYCLOAK_INIT_ERROR:
      return {
        ...state,
        error: action.payload,
        isAuthenticated: false,
        bearerToken: undefined,
        realm: undefined,
      };
    case fromActions.AUTHENTICATION_TYPES.AUTH_RESET:
    case fromActions.AUTHENTICATION_TYPES.LOGOUT_SUCCESS:
      return {
        ...INITIAL_AUTHENTICATION_STATE,
      };
    default:
      return state;
  }
};
