import { addDays, endOfToday, endOfYesterday, startOfDay, startOfToday, subSeconds } from 'date-fns';
import { ROLE } from 'features/auth/model';

export { requiredTransactionFieldsOnMultiBic, fieldsWithNoImpactOnDateRangeRequirement } from './configs/p2p/transactions/required_fields.json';

export const PAYLIB_REALM = 'paylib';

export const paylibDefaultBankId = 'BNPP';

export const statsDefaultEndDate = endOfYesterday();
export const statsDefaultStartDate = addDays(endOfYesterday(), -420);
export const transactionsDefaultRequestDate = startOfToday();
export const transactionsDefaultEndDate = endOfToday();
export const maxDaysInSeconds = 31 * 24 * 60 * 60 + 3600; // Les joies de l'heure d'hiver ...
export const transactionsMaxRangeInSeconds = 7 * 24 * 60 * 60;
export const transactionsDefaultStartDate = startOfDay(subSeconds(transactionsDefaultEndDate, transactionsMaxRangeInSeconds));

export type ROUTE_KEYS = 'SECURED_HOME' | 'STATS' | 'TRANSACTIONS' | 'ACCOUNTS' | 'OPERATIONS_BO' | 'DOCS';

export const RoutesConfig: {
  [key in ROUTE_KEYS]: {
    url: string;
    homeForRoles?: ROLE[];
  };
} = {
  SECURED_HOME: {
    url: '/secured/',
  },
  STATS: {
    url: '/secured/p2p/stats',
    homeForRoles: [ROLE.VIEWER],
  },
  TRANSACTIONS: {
    url: '/secured/p2p/transactions',
    homeForRoles: [ROLE.OPERATOR],
  },
  ACCOUNTS: {
    url: '/secured/p2p/accounts',
  },
  OPERATIONS_BO: {
    url: '/secured/bo/operations',
    homeForRoles: [ROLE.ADMIN],
  },
  DOCS: {
    url: '/secured/p2p/docs',
    homeForRoles: [ROLE.OPERATOR],
  },
};
