import { createSelector } from 'reselect';
import { AppState } from 'store/rootState';
import JwtDecode from 'jwt-decode';

import { Roles, Token, UserProfile } from '../model';
import { AuthenticationState } from './reducer';
import { PAYLIB_REALM } from '../../../config';

export const authSelector = (state: AppState) => state.authentication;

export const realmSelector = createSelector(authSelector, (auth) => auth.realm);

export const isPaylibUserSelector = createSelector(authSelector, (auth) => auth.realm === PAYLIB_REALM);

export const userProfileSelector = createSelector<AppState, AuthenticationState, UserProfile | null>([authSelector], (auth) => {
  if (!auth.userProfile) {
    return null;
  }

  let allowedBankBics: string[] = [];
  if (auth.userProfile.attributes.allowedBankBics && auth.userProfile.attributes.allowedBankBics[0]) {
    allowedBankBics = auth.userProfile.attributes.allowedBankBics[0].split(',');
  }

  const defaultBankBic = auth.userProfile.attributes.defaultBankBic ? auth.userProfile.attributes.defaultBankBic[0] : undefined;
  const allowedGroupBankBics = auth.userProfile.attributes.allowedGroupBankBics ? auth.userProfile.attributes.allowedGroupBankBics : undefined;

  return {
    ...auth.userProfile,
    fullName: auth.userProfile.firstName + ' ' + auth.userProfile.lastName,
    allowedBankBics: allowedBankBics,
    defaultBankBic: defaultBankBic,
    allowedGroupBankBics: allowedGroupBankBics,
  } as UserProfile;
});

export const tokenSelector = createSelector(authSelector, (auth) => (auth.bearerToken ? JwtDecode<Token>(auth.bearerToken) : null));

export const sessionIdSelector = createSelector(tokenSelector, (token) => {
  if (!token) {
    return null;
  }
  return token.session_state;
});

export const rolesSelector = createSelector(tokenSelector, (token) => {
  if (!token) {
    return [];
  }
  return token.realm_access.roles.filter((role) => {
    return Roles.includes(role);
  });
});
