import { UndefinableProps } from 'typings/miscs';

export interface UserProfile extends KeyCloakUserProfile {
  fullName: string;
  allowedBankBics?: string[];
  allowedGroupBankBics?: string[];
  defaultBankBic?: string;
}

export interface KeyCloakUserProfile {
  username: string;
  firstName: string;
  lastName: string;
  attributes: UndefinableProps<KeyCloakUserProfileDefaults>;
}

export interface KeyCloakUserProfileDefaults {
  allowedBankBics: string[];
  defaultBankBic?: string[];
  allowedGroupBankBics?: string[];
}

export enum ROLE {
  ADMIN = 'admin',
  OPERATOR = 'operator',
  VIEWER = 'viewer',
  OPERATOR_MODIFIER = 'operator_modifier',
  OPERATOR_SWITCH_STATUS_TO_NOT_VALIDATED = 'operator_switch_status_to_not_validated',
  OPERATOR_DELETER = 'operator_delete_B',
}

export const Roles: ROLE[] = [
  ROLE.ADMIN,
  ROLE.OPERATOR,
  ROLE.VIEWER,
  ROLE.OPERATOR_MODIFIER,
  ROLE.OPERATOR_SWITCH_STATUS_TO_NOT_VALIDATED,
  ROLE.OPERATOR_DELETER,
];

export interface Token {
  sub: string;
  session_state: string;
  name: string;
  given_name: string;
  family_name: string;
  email: string;
  realm_access: {
    roles: ROLE[];
  };
}
