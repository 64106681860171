import { combineEpics, Epic } from 'redux-observable';
import { BehaviorSubject } from 'rxjs';

import paramsEpics from 'features/params/store/epics';
import authEpics from 'features/auth/store/epics';
import announcementEpics from 'features/announcemnt/store/epics';
import shellEpics from 'features/shell/store/epics';

export const epic$ = new BehaviorSubject<Epic>(combineEpics(...shellEpics, ...authEpics, ...paramsEpics, ...announcementEpics));

export const registerEpics = (epics: Epic[]) => epics.forEach(epic => epic$.next(epic));
