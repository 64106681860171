import { History } from 'history';
import { combineReducers, Reducer, Store } from 'redux';
import { connectRouter } from 'connected-react-router';
import * as fromParamsReducer from 'features/params/store/reducer';
import * as fromShellReducer from 'features/shell/store/reducer';
import * as fromAuthenticationReducer from 'features/auth/store/reducer';
import * as fromAnnouncementReducer from 'features/announcemnt/store/reducer';
import * as fromTransactionP2PReducer from 'features/p2p/transactions/store/reducer';

export const baseReducer = (history: History) => {
  return {
    router: connectRouter(history),
    shell: fromShellReducer.reducer,
    params: fromParamsReducer.reducer,
    authentication: fromAuthenticationReducer.reducer,
    announcement: fromAnnouncementReducer.reducer,
    transactionP2P: fromTransactionP2PReducer.reducer,
  };
};

interface ReducerSlice {
  [key: string]: Reducer;
}

let asyncReducers: ReducerSlice[] = [];

export const updateBaseReducer = (store: Store, newSliceReducer: ReducerSlice, history: History) => {
  asyncReducers = [...asyncReducers, newSliceReducer];

  const reducer = combineReducers({
    ...baseReducer(history),
    ...asyncReducers.reduce((acc, asyncReducer) => {
      Object.keys(asyncReducer).forEach((key) => (acc[key] = asyncReducer[key]));
      return acc;
    }, {}),
  });
  store.replaceReducer(reducer);
};
