import { combineReducers } from 'redux';
import { createBrowserHistory } from 'history';

import { configureStore } from './configureStore';
import { baseReducer } from './baseReducer';
import { HttpClient } from './HttpClient';

export const history = createBrowserHistory();

const appStore = configureStore(combineReducers(baseReducer(history)), history);

export const store = appStore.store;

export interface EpicDeps {
  httpClient: HttpClient;
}
