import { Observable } from 'rxjs';
import { ajax } from 'rxjs/ajax';

import { Bank } from './model/banks';

export const BASE_URL = '/common-static';

export const paramsService = {
  getBanks: (sessionId: string | null): Observable<Bank[]> => {
    const url = `/banks-config/backoffice-banks-config.json?${sessionId}`;
    return ajax.getJSON<Bank[]>(url);
  },
};
