import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { FullPageSpinner } from 'features/shared/Spinner/Spinner';
import { ConnectedRouter } from 'connected-react-router';
import { history, store } from './store';
import { configureI18n } from './i18n/configureI18n';

const Routes = lazy(() => import('./Routes'));

export const App = () => {
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    configureI18n({ debug: process.env.NODE_ENV === 'development' }).then(() => {
      setIsReady(true);
    });
  });

  return (
    <>
      {isReady && (
        <Suspense fallback={<FullPageSpinner />}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <Routes />
            </ConnectedRouter>
          </Provider>
        </Suspense>
      )}
    </>
  );
};
