import * as fromActions from './actions';

export interface ShellState {
  ready: boolean;
}

export const INITIAL_SHELL_STATE: ShellState = {
  ready: false,
};

export const reducer = (state = INITIAL_SHELL_STATE, action: fromActions.ActionsType): ShellState => {
  switch (action.type) {
    case fromActions.SHELL_TYPES.CHECK_APP_READY:
      return {
        ready: false,
      };
    case fromActions.SHELL_TYPES.APP_READY:
      return {
        ready: true,
      };
    default:
      return state;
  }
};
