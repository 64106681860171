import { ActionsObservable, StateObservable } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { AjaxError } from 'rxjs/ajax';
import { ofType } from '@martin_hotell/rex-tils';

import * as fromAuthActions from 'features/auth/store/actions';
import { sessionIdSelector } from 'features/auth/store/selectors';
import { AppState } from 'store/rootState';
import { paramsService } from '../service';
import * as fromActions from './actions';

export const paramsRequestTrigger = (action$: ActionsObservable<fromAuthActions.ActionsType>) =>
  action$.pipe(
    ofType(fromAuthActions.AUTHENTICATION_TYPES.KEYCLOAK_INIT_SUCCESS),
    filter(action => action.payload.isAuthenticated === true),
    map(() => fromActions.Actions.paramsRequest())
  );

export const paramsRequest = (action$: ActionsObservable<fromActions.ActionsType>, state$: StateObservable<AppState>) =>
  action$.pipe(
    ofType(fromActions.PARAMS_TYPES.PARAMS_REQUEST),
    mergeMap(() => {
      return paramsService.getBanks(sessionIdSelector(state$.value)).pipe(
        map(banks => fromActions.Actions.paramsResponse({ banks })),
        catchError((error: AjaxError) => of(fromActions.Actions.paramsError(error)))
      );
    })
  );

export const paramsResponse = (action$: ActionsObservable<fromActions.ActionsType>) =>
  action$.pipe(
    ofType(fromActions.PARAMS_TYPES.PARAMS_RESPONSE),
    map(() => fromActions.Actions.paramsReady())
  );

export default [paramsRequestTrigger, paramsRequest, paramsResponse];
