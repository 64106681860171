import React from 'react';
import ReactDOM from 'react-dom';
import { Instance } from 'flatpickr/dist/types/instance';
import { App } from './App';

import './theme/index.scss';

window.paylibDatePickers = new Map<string, Instance>();

ReactDOM.render(<App />, document.getElementById('root'));
