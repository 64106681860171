import Keycloak from 'keycloak-js';
import { PAYLIB_REALM } from 'config';

export const keycloakRealmResolver = (host: string): string => {
  if (host.match(/^backoffice(-v2|)\..+/)) {
    return PAYLIB_REALM;
  } else {
    const realmFound = host.match(/^([a-z]+)-backoffice(-v2|)\..+$/);
    return realmFound === null ? PAYLIB_REALM : realmFound[1];
  }
};

export const realm = keycloakRealmResolver(window.location.hostname);

export const keycloak = Keycloak(`/keycloak/${realm}.json`);
