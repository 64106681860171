import { ActionsObservable } from 'redux-observable';
import { zip } from 'rxjs';
import { first, flatMap, map } from 'rxjs/operators';
import { ofType } from '@martin_hotell/rex-tils';

import * as fromActions from './actions';
import * as fromAuthActions from 'features/auth/store/actions';
import * as fromParamsActions from 'features/params/store/actions';

export const checkAppReady = (action$: ActionsObservable<fromActions.ActionsType>) =>
  action$.pipe(
    ofType(fromActions.SHELL_TYPES.CHECK_APP_READY),
    flatMap(() => {
      return zip(
        action$.pipe(
          ofType(fromAuthActions.AUTHENTICATION_TYPES.USER_PROFILE_READY),
          first()
        ),
        action$.pipe(
          ofType(fromParamsActions.PARAMS_TYPES.PARAMS_READY),
          first()
        )
      );
    }),
    map(() => fromActions.Actions.appReady())
  );

export default [checkAppReady];
