import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from 'i18next-xhr-backend';
import { cacheId } from '../features/shared/Utils/Cache';

export const configureI18n = ({ debug }: { debug: boolean }) => {
  return i18n
    .use(backend)
    .use(initReactI18next)
    .init({
      debug,
      lng: 'fr',
      fallbackLng: 'fr',
      ns: 'commons',
      defaultNS: 'commons',
      backend: {
        loadPath: `/locales/{{lng}}/{{ns}}.json?${cacheId(new Date(), 5)}`,
      },
      interpolation: { escapeValue: false },
    });
};
